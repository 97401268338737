<template>
  <div id="about-us-page-banner">
    <picture>
      <source
        media="(min-width:1000px)"
        :srcset="require(`@/assets/images/backgrounds/about-us-banner.webp`)"
        type="image/webp"
      />
      <source
        media="(min-width:1000px)"
        :srcset="require(`@/assets/images/backgrounds/about-us-banner.jpg`)"
        type="image/jpg"
      />
      <source
        :srcset="
          require(`@/assets/images/backgrounds/about-us-banner-mobile.webp`)
        "
        type="image/webp"
      />
      <img
        id="about-us-banner"
        :src="require(`@/assets/images/backgrounds/about-us-banner-mobile.jpg`)"
        alt="product-pic"
        width="100%"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: "about-hero",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#about-us-page-banner {
  font-size: 0;
}
</style>

<template>
  <div>
    <about-hero />
    <story-section />
  </div>
</template>
<script>
// @ is an alias to /src
import AboutHero from "@/components/AboutPage/AboutHero.vue";
import StorySection from "@/components/AboutPage/StorySection.vue";

export default {
  name: "About",
  components: {
    AboutHero,
    StorySection,
  },
  metaInfo: {
    title: "About Us",
  },
};
</script>

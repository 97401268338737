<template>
  <section
    class="section1"
    :style="{ backgroundImage: `url(${backgroundUrl})` }"
  >
    <!-- <div class="picture inline">
      <img src="@/assets/background.jpg" class="profile-pic" />
    </div> -->
    <div class="story-container">
      <p class="title tangerine-font">About Us</p>
      <p class="story-about-us">
        Only when our body system is functioning harmoniously within us, we are
        able to enjoy greater happiness and experience what quality living is
        all about! With the passion to achieve balance and harmony much needed
        by our body system, Alpha Global Wellness is constantly seeking the most
        advanced technology to bring out the best of nature's energy and healing
        methods.
        <br />
        <br />
        As the co-founder of Detox@Balance & Cleanse(HQ Centre), we are
        delighted to bring to you the Quantum Cellular Detox therapy as part of
        a progressive and preventive health program to enable our body to
        Regenerate, Renew and Self-Heal!
        <br />
        <br />
        We will share our discovery with you as we continue to explore more
        wonderful inventions around the world that could bring us greater health
        with a peace of mind.
      </p>
    </div>
  </section>
</template>

<script>
import backgroundUrl from "@/assets/images/branding/AlphaLogoTransparent.png";
export default {
  name: "story-section",
  data() {
    return {
      backgroundUrl,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profile-pic {
  height: 400px;
  width: 400px;
  //   border: black solid 1px;
  border-radius: 50%;
}
.section1 {
  min-height: 50vh !important;
  // background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  background-color: #aec99c;
  background-size: auto 170%;
  background-repeat: no-repeat;
  background-position: right;
}
@media only screen and (max-width: 1024px) {
  .section1 {
    background-position: center;
    background-size: auto 100%;
  }
}
.inline {
  float: left;
  width: 50%;
  padding-top: 15%;
  padding-bottom: 15%;
}
.story-container {
  padding-bottom: 3em !important;
  margin: auto;
  width: 70%;
  padding: 3em;
  text-align: center;
  font-size: 1.4em;
  .story-about-us {
    line-height: 26pt;
  }
}
.title {
  text-align: center !important;
  margin: 0;
}
.picture {
  padding-bottom: 0% !important;
}
@media only screen and (max-width: 680px) {
  .story-container {
    width: 90%;
    padding: 0;
    padding-top: 3em;
  }
}
@media only screen and (max-width: 1200px) {
  .profile-pic {
    margin-top: 20%;
    height: 280px;
    width: 280px;
  }
}
@media only screen and (max-width: 1024px) {
  .profile-pic {
    margin-top: 0%;
  }
  .picture {
    padding-bottom: 0% !important;
  }
  .inline {
    float: none;
    width: 100%;
  }
  section {
    min-height: 50vh !important;
  }
  .story-container {
    text-align: center;
  }
}
</style>
